body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Microsoft YaHei", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.logo {
  background: url("https://llmquant.com/dist/assets/quantpedia-logo.png");
  background-size: 120px, 43px;
  width: 120px;
  height: 40px;
  /* background: rgba(0, 0, 0, 0.2); */
  margin: 17px 10px 16px 20px;
  float: left;
}

.ant-table.ant-table-middle .ant-table-title, .ant-table.ant-table-middle .ant-table-footer, .ant-table.ant-table-middle thead > tr > th, .ant-table.ant-table-middle tbody > tr > td {
  padding: 1px 8px !important;
}

.ant-list-sm .ant-list-item {
  padding: 2px !important;
}

.highlight-row {
  background: rgb(249,198,205);
}

.alert-row {
  background: rgba(143, 121, 223, 0.6);
}

/* http://react-china.org/t/topic/33846/3 */
.ant-table-header {
  scrollbar-color: transparent transparent;
}

.ant-table-header::-webkit-scrollbar {
  background-color: transparent;
}

.import-output {
  height: 500px;
}

.CodeMirror {
  height: 100% !important;
}

.App {
  padding: 20px;
}

.red-row {
  background: #ffccc7;
}

.yellow-row {
  background: #ffffb8;
}

.green-row {
  background: #d9f7be;
}

.ant-list-item:hover {
  box-shadow: 0 0 4px #888;
}

/* https://stackoverflow.com/questions/64961752/how-do-i-change-the-color-of-selected-menu-item-in-ant-design */

/* .conferenceMenu > .ant-menu-item-selected {
 background-color: rgb(230,247,255) !important;
} */

.conferenceMenu {
  background-color: rgb(242,242,242) !important;
}

.react-codemirror2 {
  width: 100%;
  height: 100%;
  position: relative;
}

.anticon-file {
  visibility: hidden;
}

.markdownContainer {
  width: 100%;
  overflow: hidden;
  background-color: rgb(251,240,217);
  padding: 10px;
}

.markdownContainer img {
  max-width: 100%;
  height: auto;
}

/*.ant-modal-content {*/
/*  padding: 0 !important;*/
/*  background-color: transparent !important;*/
/*  box-shadow: none !important;*/
/*}*/
